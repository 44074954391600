
import { defineComponent } from 'vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import type { CampaignsDetailsProgressInfoType } from '@/definitions/campaigns/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmSpinner,
    TmProgress,
  },
  props: {
    sendingText: {
      type: String,
      default: 'Sending emails...',
    },
    infos: {
      type: Array as PropType<CampaignsDetailsProgressInfoType[]>,
    },
  },
})
